import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Settingdata	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSystemuserObj 			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSystemuserObj.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting) || {};
	
	/*const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;//-*/

	//--VIEWS_VARS--/
	const uISEditpengelola			= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")
	const [uDatasettingObj,setDatasettingObj]	= React.useState(uSystemsettingObj);
	const [uDOMchange,setDOMchange] 			= React.useState(false);
	
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	//const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	/*//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--*/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikDlgsimpan = () => {}
	//--END HANDEL--/

	//--INIT_FUNC--/
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/

	const contentSettingdiskon = () => {
		return (
		<CCard className="classbgcard">
		<CCardHeader><b>Diskon Terapi</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="4" className="classfontsmaller">Diskon Maksimal</CCol>
		<CCol xs="6" lg="3" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.persen_diskonmax||""}
				onChange={(e)=>{setDatasettingObj({...uDatasettingObj,persen_diskonmax:e.target.value});setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uISEditpengelola}
				className="text-end"
				id="inpsettingdiskon"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentSettingshadow = () => {
		if(uTokenObj.userhak !== "FREEROLE") return "";

		return (
		<CCard className="classbgcard mt-3">
		<CCardHeader><b>Setting Server</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="4" className="classfontsmaller">Persen Shadow</CCol>
		<CCol xs="6" lg="3" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.persen_shadow||"0"}
				onChange={(e)=>{setDatasettingObj({...uDatasettingObj,persen_shadow:e.target.value});setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uISEditpengelola}
				className="text-end"
				id="inpsettingshadow"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentSettingdata = () => {
		return (
		<CCard className="classbgcard h-100">
		<CCardHeader><b>Setting Lainnya</b>
			<small className="classfontsmaller fst-italic"> &middot; Untuk Semua Outlet Klinik</small>
		</CCardHeader>

		<CCardBody>
		<CRow className="my-2">
		<CCol xs="6" lg="3" className="classfontsmaller">Jml Tahun Laporan</CCol>
		<CCol xs="6" lg="3" className="">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.jml_tahun_laporan||""}
				onChange={(e)=>{setDatasettingObj({...uDatasettingObj,jml_tahun_laporan:e.target.value});setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uISEditpengelola}
				className="text-end"
				id="inpsettingtahunjml"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="6" lg="3" className="classfontsmaller">Tahun Lahir Awal</CCol>
		<CCol xs="6" lg="3" className="">
			<CFormInput size="sm" type="number"
				value={uDatasettingObj.start_tahunlahir||""}
				onChange={(e)=>{setDatasettingObj({...uDatasettingObj,start_tahunlahir:e.target.value});setDOMchange(true);}}
				onFocus={(e)=>e.target.select()}
				disabled={!uISEditpengelola}
				className="text-end"
				id="inpsettingtahunlahir"/>
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = (_PAGE) => {}
	const apiSimpansetting = (_FIELD) => {
		_FIELD	= (_FIELD||"").trim().toLowerCase();

		let vEldisabled;
		if(_FIELD==="persen_diskonmax")
			vEldisabled = document.getElementById("inpsettingdiskon")
		else if(_FIELD==="jml_tahun_laporan")
			vEldisabled = document.getElementById("inpsettingtahunjml")
		else if(_FIELD==="start_tahunlahir")
			vEldisabled = document.getElementById("inpsettingtahunlahir")
		else if(_FIELD==="persen_shadow")
			vEldisabled = document.getElementById("inpsettingshadow")
		
		vEldisabled && (vEldisabled.readOnly = true)

		/*//--TESTING_FRONTEND--/
		console.log("(Konfigdata - apiSimpansetting) uDataklinikObj => "+uDatasettingObj[_FIELD])
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDataidentitasObj	= {
			field: _FIELD,
			value: uDatasettingObj[_FIELD]
		};
		const vDATAS	= JSON.stringify({
			send_datasetting: JSON.stringify(vDataidentitasObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_setting";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true)
			vEldisabled && (vEldisabled.readOnly = false)
			if(output_string.sukses) {
				const vSystemsettingObj	= uSystemsettingObj;
				vSystemsettingObj[_FIELD] = uDatasettingObj[_FIELD];

				uDispatch({type: "set", gSystemsetting: vSystemsettingObj});
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Settingdata - apiSimpansetting) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.readOnly = false)
			console.log("(Settingdata - apiSimpansetting) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/


	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))

		/*//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

		/*//--PAGE_LOAD_PER_PAGE--/
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}
		//--END PAGE_LOAD_PER_PAGE--*/

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatasettingObj(uSystemsettingObj);
    	}
	},[])
	React.useEffect(()=>{
		if(uDatasettingObj.persen_diskonmax === undefined 
			|| uDatasettingObj.persen_diskonmax === null
			) return;
		if(!uDOMchange) return;
	
		let vTimeout = setTimeout(()=>{ apiSimpansetting("persen_diskonmax");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.persen_diskonmax])
	React.useEffect(()=>{
		if(uDatasettingObj.persen_shadow === undefined 
			|| uDatasettingObj.persen_shadow === null
			) return;
		if(!uDOMchange) return;
	
		let vTimeout = setTimeout(()=>{ apiSimpansetting("persen_shadow");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.persen_shadow])
	React.useEffect(()=>{
		if(uDatasettingObj.jml_tahun_laporan === undefined 
			|| uDatasettingObj.jml_tahun_laporan === null
			) return;
		if(!uDOMchange) return;
			
		let vTimeout = setTimeout(()=>{ apiSimpansetting("jml_tahun_laporan");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.jml_tahun_laporan])
	React.useEffect(()=>{
		if(uDatasettingObj.start_tahunlahir === undefined 
			|| uDatasettingObj.start_tahunlahir === null
			) return;
		if(!uDOMchange) return;
			
		let vTimeout = setTimeout(()=>{ apiSimpansetting("start_tahunlahir");setDOMchange(false); },1000);
		return () => clearTimeout(vTimeout); 
	},[uDatasettingObj.start_tahunlahir])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)//->

	return (
		<>
		<CForm>
		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">{contentSettingdiskon()}{contentSettingshadow()}</CCol>
		<CCol md="6" className="mb-3">{contentSettingdata()}</CCol>
		</CRow>
		</CForm>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Settingdata