import React from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { UFunc,cBulanPendek } from '../../helpers/functions'

const Headersubdashboard = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction)
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uCabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const [uInitloading,setInitloading]		= React.useState(false);
	const [uDataformObj,setDataformObj] 	= React.useState({});
	const uCabanguserArr			= uTokenObj.userhak==="FREEROLE"
		? uCabangArr : (uCabangArr.filter(vItems=>(uTokenObj.user_cabangarr||[]).includes(vItems.id)));

	React.useEffect(()=>{
		return () => {
			/*setDataformObj({})
			uKeywordObj.filter_dashboard = undefined;
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			uDispatch({type: "set", gInitHeaderAction: {}});//-->HARUS_DIKOSONGIN_DULU_BIAR_ADA_EFFECT--*/
		}
	},[])
	React.useEffect(()=>{
		const vHeaderTmpObj	= uHeaderActionObj;
		uDispatch({type: "set", gInitHeaderAction: {}});//-->HARUS_DIKOSONGIN_DULU_BIAR_ADA_EFFECT--/

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_dashboard = uDataformObj;
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		vHeaderTmpObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
	},[uEfekview])//-*/
	React.useEffect(()=>{
		//console.log("Headersubdashboard - uDataformObj.id_cabang => "+(uDataformObj.id_cabang));
		if(uDataformObj.id_cabang===undefined) {
			uDataformObj.id_cabang = localStorage.getItem("lastcabangid");
			setEfekview(!uEfekview);
		}
		if(uDataformObj.tgl_filter===undefined) {
			const vTglNow	= new Date();
			uDataformObj.tgl_filter = 
				UFunc.DbDate(new Date(vTglNow.getFullYear(),
					vTglNow.getMonth(),1))
			setEfekview(!uEfekview);
		}
	},[uDataformObj,
		uDataformObj.id_cabang,
		uDataformObj.tgl_filter
	])

	//console.log("Headersubdashboard - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>
		
		<CForm>
			<Link onClick={()=>setEfekview(!uEfekview)} className="d-none me-2 classcusrsorpointer classikon classikonreset"/>
			<CInputGroup size="sm">
			{uCabangArr.length > 1 && (
			<>
			<CFormSelect size="sm"
				className="w-auto"
				value={uDataformObj.id_cabang}
				onChange={(e)=>{uDataformObj.id_cabang=e.target.value;setEfekview(!uEfekview)}}
				id="inpcabangid">
				{uCabanguserArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.id} key={vKeys}>{(vItems.cabang_nama||"").toUpperCase()}</option>
				)				
				})}
			</CFormSelect>
			<span className="me-1"/>
			</>
			)}
			<CFormSelect size="sm"
				className="w-auto"
				value={uDataformObj.tgl_filter}
				onChange={(e)=>{uDataformObj.tgl_filter=e.target.value;setEfekview(!uEfekview)}}
				id="inpperiode">
				{[...new Array(6)].map((_,vKeys)=>{
					const vTglNow	= new Date();
					const vMonthidx	= vTglNow.getMonth() - vKeys < 0 
						? (vTglNow.getMonth() - vKeys) + 12 
						: vTglNow.getMonth() - vKeys;
					const vYear		= vTglNow.getMonth() - vKeys < 0 
						? vTglNow.getFullYear()-1 : vTglNow.getFullYear();//-*/

					const vTglsatu	= new Date(vYear,vMonthidx,1);
					const vValue 	= UFunc.DbDate(vTglsatu);
					const vCaption 	= (cBulanPendek[vMonthidx]||"").toUpperCase()+" "+vYear; 

					return (
					<option value={vValue} key={vKeys}>{vCaption}</option>
					)
				})}
				{/*(()=>{
					const vYear 	= 2021;
					const vMonthidx = 9;

					const vValue 	= 
						UFunc.DbDate(new Date(vYear,vMonthidx,1))
					const vCaption 	= (cBulanPendek[vMonthidx]||"").toUpperCase()+" "+vYear; 

					return (
					<option value={vValue}>{vCaption}</option>
					)
				})()*/}
			</CFormSelect>
			</CInputGroup>
		</CForm>
		</>
	)
}

export default Headersubdashboard
