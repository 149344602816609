import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CForm,
	CInputGroup,
	CInputGroupText,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CFormCheck,
	CBadge,
	CImage,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
let pjson     = require('../../package.json');

const Nota = (props) => {
	const uComponentname 	= Nota.name;
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uHistory  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uEfekapp			= useSelector(state => state.effectApp);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uSystemsettingObj	= useSelector(state => state.gSystemsetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const [uEfekview,setEfekview] = React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitPengelola	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) 
			: (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uFontstyleArr						= [
		{value:"Courier, Courier New, Sans Serif",caption:"Courier, Courier New, Sans Serif"},
		{value:"Verdana,Arial,Helvetica",caption:"Verdana,Arial,Helvetica"},
		{value:"Calibri, Trebuchet, Garamond",caption:"Calibri, Trebuchet, Garamond"},
		{value:"Monaco, DejaVu Sans Mono, OCR-A",caption:"Monaco, DejaVu Sans Mono, OCR-A"},
		];
	const uUrllogoDefault	= pjson.homepage+"api/images/header_nota.png";
	const uDatanotaObj		= {
		tgl_bayar: UFunc.toDatetime(), tgl_periksa: UFunc.toDate(),
		status_bayar: "KASIR",
		nilai_uang:500000, nilai_bayar:400000, nota:(UFunc.DbDate()).replace(new RegExp("-","ig"),"")+"004",
		nama_dokter: "Nama Dokter, SpPros, DRG",
		pasien_id: "40927", pasien_caption:"Nama Contoh Pasien",
		nilai_bruto: 409120, diskon:9120,
		note_dokter:"Datang Lagi", note_nota:"Ini Catatan Tambahan",
		metode_payment:"DEBET BANK LAIN",id_payment:"12",ket_payment:"Dasd Aadasdad",
		kasir:uTokenObj.usercaption||"UNDF",id_rekammedis:228206,
		janjian_arr:[{jam_awal:"10:00:00",tgl_antri:"2022-11-13",id:"49"},
			{jam_awal:"10:00:00",tgl_antri:"2022-11-15",id:"50"}],
		items_arr:[
			{status_ada_garansi:"YA",tgl_batas_garansi:null,tarif_terapi:138680,caption_terapi:"Nama Terapi 1",id_terapi_sub:"1787",id_item:"99393091"},
			{status_ada_garansi:"YA",tgl_batas_garansi:null,tarif_terapi:170600,caption_terapi:"Nama Terapi 2",id_terapi_sub:"1786",id_item:"99393092"},
			{status_ada_garansi:"TIDAK",tgl_batas_garansi:null,tarif_terapi:99840,caption_terapi:"Nama Terapi 3",id_terapi_sub:"733",id_item:"99393093"}
		]
	};
	const refFileImg 			= React.useRef();
	const uHTMLstylenota		= useSelector(state => state.gHTMLstyleNota);

	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit			= (localStorage.getItem("lastcabangid")||"0")
	const uCabanginitObj 		= useSelector(state => state.gCabangatribut) || {};

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas			= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri			= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize				= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle			= uSettingcetakObj.fontstyle || uFontstyleArr[0].value;
	const uViewLogo				= uSettingcetakObj.view_image_nota ||"TIDAK";
	//--END SETTING_CETAK--/

	//---FORMDATA--/
	const [uFormfieldObj,setFormfieldObj]	= React.useState({
		view_image_nota: uViewLogo,
		nota_lebar: uLebarKertas,
		margin_kiri: uMarginKiri,
		font_size: uFontsize,
		font_style: uFontstyle,
		url_image_nota: (uSystemsettingObj.url_image_nota) === pjson.homepage+"api/images/header_nota.png"
			|| (uSystemsettingObj.url_image_nota||"") === ""
				? "" : uSystemsettingObj.url_image_nota,
		footer_nota: uCabanginitObj.footer_nota,
	});
	//---END FORMDATA--/

	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const uElBtnsimpan	= document.getElementById("btnSimpan");
	//--END GLOBAL_VARS--/


	//---HANDLE--/
	const hdlKlikReset=()=>{ initAwal(); }
	const hdlKlikPilihlogo=()=>{ refFileImg.current.click(); }
	const hdlChangeLogo=(_EV)=>{
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(Pasieninput - hdlChangeFoto) fileUploaded => "+fileUploaded);

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64 = result;
			uFormfieldObj.url_image_nota = (fileBase64);
			setEfekview(!uEfekview);
			//console.log("File Is", fileBase64);
			_EV.target.value = '';
		}).catch(err => {
			_EV.target.value = '';
			console.log(err);
		});
	}
	const hdlKlikHapuslogo = async() => {
		const vConfirm	= 
			"<B>"+(uBahasaObj.caption_hapuslogo||"caption_hapuslogo")+"</B>.."+
			"<BR><BR>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+""+
		"";
		const vFuncnext	= function() {
			uFormfieldObj.url_image_nota = undefined;
			setEfekview(!uEfekview);
		}

		if(await Konfirm(vConfirm)) vFuncnext();
	}
	const hdlKlikSimpan=()=>{ apiProsessimpan(); }
	const hdlKlikCetak=()=>{
		var vContent 	= contentCetaknota();
		var pri 		= uFrameExport.contentWindow;
		//console.log(vContent);
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	const initAwal=()=>{
		setFormfieldObj({
			view_image_nota: uViewLogo,
			nota_lebar: uLebarKertas,
			margin_kiri: uMarginKiri,
			font_size: uFontsize,
			font_style: uFontstyle,
			url_image_nota: uSystemsettingObj.url_image_nota || uUrllogoDefault,
			footer_nota: uCabanginitObj.footer_nota,
		})
	}
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const loadContentForm=()=>{
		if(uHTMLError500) return "";

		//console.log("(Nota - loadContentForm) uInpSettinglogourl: "+uInpSettinglogourl);
		return (
		<CForm className="mx-0 mx-lg-4">
		<CRow variant="checkbox" className="my-2 mx-0">
			<CCol className="px-0 ">
			<CFormCheck 
				size="sm"
				name="inpwithlogo" 
				onChange={(e)=>{
					uFormfieldObj.view_image_nota = (uFormfieldObj.view_image_nota||"TIDAK")==="TIDAK"
						? "YA" : "TIDAK";
					setEfekview(!uEfekview)
				}}
				checked={(uFormfieldObj.view_image_nota||"TIDAK")==="YA"}
				label={(uBahasaObj.caption_denganlogo||"Dengan Logo").toUpperCase()}
				value={"YA"} 
				id="inpwithlogo"/> 
			</CCol>
			<CCol className="text-end px-0">
				{(uFormfieldObj.url_image_nota||"")==="" ? (
				<>
				<input
					type="file"
					accept="image/png,image/jpeg,image/gif"
					ref={refFileImg}
					onChange={(e)=>hdlChangeLogo(e)}
					className="d-none"
					id="inpfileimg"/>

				<CBadge color="dark">{(uBahasaObj.caption_logodefault||"Default Logo").toUpperCase()}</CBadge>
				{(uIsInitPengelola) && (
				<>
				<span className="mx-1">&middot;</span>
				<CTooltip content={"Ganti Logo Nota"}>
				<CButton color="info" size="sm"
					onClick={()=>hdlKlikPilihlogo()}>
					<CIcon icon="cilFile" className="fw-bolder text-white"/>
				</CButton>
				</CTooltip>
				</>
				)}
				</>
				) : (
				<>
				<CBadge color="success">
					{(uBahasaObj.caption_logocustom||"caption_logocustom").toUpperCase()}
				</CBadge>
				{(uIsInitPengelola) && (
				<>
				<span className="mx-1">&middot;</span>
				<CLink className="classcursorpointer classikontabel classikonhapus"
					onClick={()=>hdlKlikHapuslogo()}/>
				</>
				)}
				</>
				)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2 align-items-end">
			<CCol xs="5" lg="4" className="">
				<span className="classpetunjuk">{""+(uBahasaObj.caption_lebarkertas||"caption_lebarkertas")+""}</span>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					step="5" min="0"
					className="text-end"
					value={uFormfieldObj.nota_lebar||"0"}
					onChange={(e)=>{uFormfieldObj.nota_lebar=e.target.value;setEfekview(!uEfekview); }}
					onFocus={(e)=>(e.target.select())}
					id="inplebarkertas"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol xs="2" lg="4" className=""/>
			<CCol xs="5" lg="4" className="">
				<span className="classpetunjuk">{""+(uBahasaObj.caption_marginkiri||"caption_marginkiri")+""}</span>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min="0" step="5"
					className="text-end"
					value={uFormfieldObj.margin_kiri||"0"}
					onChange={(e)=>{uFormfieldObj.margin_kiri=e.target.value;setEfekview(!uEfekview); }}
					onFocus={(e)=>(e.target.select())}
					id="inpmarginkiri"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol xs="3" className=""/>
		</CRow>
		<CRow className="my-2">
			<CCol xs="5" lg="4" className="">
				<span className="classpetunjuk">{""+(uBahasaObj.caption_fontsize||"caption_fontsize")+""}</span>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min="7" max="20"
					className="text-end"
					value={uFormfieldObj.font_size||"7"}
					onChange={(e)=>{uFormfieldObj.font_size=e.target.value;setEfekview(!uEfekview); }}
					onFocus={(e)=>(e.target.select())}
					id="inpfontsize"/>
				<CInputGroupText><small>px</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol xs="2" lg="4" className=""/>
			<CCol xs="5" lg="4" className="">
				<span className="classpetunjuk">{""+(uBahasaObj.caption_fontfamily||"caption_fontfamily")+""}</span>
			<CFormSelect size="sm"
				value={uFormfieldObj.font_style}
				onChange={(e)=>{uFormfieldObj.font_style=e.target.value;setEfekview(!uEfekview); }}
				id="inpfontfamily">
				{uFontstyleArr.map((vItems,vKeys)=>{
					return (<option value={vItems.value} key={vKeys}>{vItems.caption}</option>);
				})}
			</CFormSelect>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol className="">
			<CFormInput size="sm" maxLength={255}
				readOnly={true}
				className="text-capitalize"
				value={uFormfieldObj.footer_nota||""}
				onChange={(e)=>{uFormfieldObj.footer_nota=e.target.value;setEfekview(!uEfekview); }}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				placeholder={"--"+(uBahasaObj.caption_footernota||"caption_footernota").toUpperCase()+".."}
				id="inpfooternota"/>
			</CCol>
		</CRow>
		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
			<CCol className="classpetunjuk">
			*) {UFunc.renderHTML(uBahasaObj.petunjuk_settingnota || "petunjuk_settingnota")}
			</CCol>
		</CRow>

		</CForm>
		)//->
	}
	const loadContentView=()=>{
		if(uHTMLError500) return "";

		const vDatanotaObj		= uDatanotaObj;

		const vNilaitagihan	= parseInt(vDatanotaObj.nilai_bruto)
			-  parseInt(vDatanotaObj.diskon);
		const vNilaisisa	= vNilaitagihan 
			-  parseInt(vDatanotaObj.nilai_uang);
		//console.log("(Nota - loadContentView) uDatasetting: "+JSON.stringify(uDatasetting));
		return (
		<>
		<div className="d-none">{uUrllogoDefault}</div>
		<div className="d-none">{uSystemsettingObj.url_image_nota}</div>
		<CRow className="">
			{(uViewLogo==="YA") && (
			<CCol xs="12" className="d-flex justify-content-center">
			<CImage src={(uSystemsettingObj.url_image_nota||"").trim()!==""?uSystemsettingObj.url_image_nota:uUrllogoDefault} height="80"/>
			</CCol>
			)}
			<CCol xs="12" className="text-center">
			<strong>{uCabanginitObj.nama_klinik||"DENTES"}</strong>
			</CCol>

			{(uCabanginitObj.alamat_klinik||"").trim()!=="" && (
			<CCol xs="12" className="text-center">{UFunc.renderHTML(uCabanginitObj.alamat_klinik||"")}</CCol>
			)}
			
			{(uCabanginitObj.telp_hp||"").trim()!=="" && (
			<CCol xs="12" className="text-center">Telp/WA.{uCabanginitObj.telp_hp||""}</CCol>
			)}
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>

		<CRow className="mx-0">
			<CCol xs="5">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
			<CCol xs="7">{UFunc.WaktuPendek(vDatanotaObj.tgl_bayar)}</CCol>
			<CCol xs="5">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol xs="7">{vDatanotaObj.nota||"UNDF"}</CCol>
			<CCol xs="5">{uBahasaObj.word_pasien||"word_pasien"}</CCol>
			<CCol xs="7"><b>{vDatanotaObj.pasien_caption}</b></CCol>
		</CRow>
		<CRow className="mx-0 mt-3">
			<CCol xs="5">{uBahasaObj.word_dokter||"Dokter"}</CCol>
			<CCol xs="7">{vDatanotaObj.nama_dokter}</CCol>
			<CCol xs="5">{uBahasaObj.word_kasir||"word_kasir"}</CCol>
			<CCol xs="7">{vDatanotaObj.kasir}</CCol>
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>

		{(vDatanotaObj.items_arr||[]).map((vItems,vKeys)=>{
			return(
				<CRow className="" key={vKeys}>
				<CCol xs="1" className="px-1 text-end">{(vKeys+1)}</CCol>
				<CCol xs="8" className="px-1 fw-bolder">{vItems.caption_terapi||"UNDF"}</CCol>
				<CCol xs="3" className="text-end">{UFunc.formatAngka(parseInt(vItems.tarif_terapi))}</CCol>
				</CRow>
			);
		})}
		<div className="my-2 classborderbottom classborderdashed"/>
		<CRow className="">
			<CCol xs="8" className="text-end">{uBahasaObj.word_jumlah||"word_jumlah"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(vDatanotaObj.nilai_bruto)}</CCol>
		</CRow>
		<CRow className="">
			<CCol xs="8" className="text-end">{uBahasaObj.word_diskon||"word_diskon"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(vDatanotaObj.diskon||"0")}</CCol>
		</CRow>
		<CRow className="">
			<CCol xs="8" className="text-end">&nbsp;</CCol>
			<CCol xs="4" className="my-2 classborderbottom classborderdashed"/>
		</CRow>
		<CRow className="">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_nilaitagihan||"Nilai Tagihan"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(vNilaitagihan)}</CCol>
		</CRow>
		<CRow className="fw-bolder text-uppercase">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_jmlbayar||"caption_jmlbayar"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(vDatanotaObj.nilai_uang)}</CCol>
		</CRow>
		<CRow className="">
			<CCol xs="8" className="text-end">&nbsp;</CCol>
			<CCol xs="4" className="my-2 classborderbottom classborderdashed"/>
		</CRow>
		<CRow className="fw-bolder">
			<CCol xs="8" className="text-end">{uBahasaObj.caption_kembalian||"caption_kembalian"} (Rp)</CCol>
			<CCol xs="4" className="text-end">{UFunc.formatAngka(Math.abs(vNilaisisa))}</CCol>
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>
		<CRow className="mx-0">
			<CCol>Metode Payment</CCol>
			<CCol className="text-end fw-bolder">{vDatanotaObj.metode_payment}</CCol>
		</CRow>
		<CRow className="mx-0">
			<CCol xs="3"/>
			<CCol xs="9" className="fst-italic text-end">{vDatanotaObj.ket_payment}</CCol>
		</CRow>

		<div className="my-2 classborderbottom classborderdashed"/>
		<CRow className="mx-0">
			<CCol xs="12" className="fw-bolder">Catatan</CCol>
			<CCol xs="1" className=""/>
			<CCol xs="11" className="px-0">{vDatanotaObj.note_dokter} &middot; <i>{vDatanotaObj.note_nota}</i></CCol>
			
			<CCol xs="1" className=""/>
			<CCol xs="11" className="px-0">
			<ol>
			{(vDatanotaObj.janjian_arr||[]).map((vItems,vKeys)=>{
				const {
					id,tgl_antri,jam_awal,no_urut,nama_dokter
				} = vItems;

				return (
					<li key={vKeys}>
					Tgl <b>{UFunc.HariAngka(tgl_antri)}</b><span> &middot; </span>
					Jam <b>{(parseInt(no_urut||0) > 0 ? "Urutan No-"+no_urut : (jam_awal||"").substring(0,5) )}</b>
				</li>
				);
			})}
			</ol>
			</CCol>
		</CRow>


		{(uCabanginitObj.footer_nota||"").trim()!=="" && (
		<>
		<div className="my-2 classborderbottom classborderdashed"/>
		<CRow className="">
			<CCol xs="12" className="font-italic text-center text-capitalize">{uCabanginitObj.footer_nota}</CCol>
		</CRow>
		</>
		)}
		</>
		)
	}
	const contentCetaknota = () => {
		if(JSON.stringify(uDatanotaObj||{}) === "{}") return "";
		//console.log("(Bayar - contentCetaknota) uDatanotaObj => "+JSON.stringify(uDatanotaObj))

		let vHTMLs 	= uHTMLstylenota;
		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= uViewLogo==="YA";// (uSystemsettingObj.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= (uSystemsettingObj.url_image_nota||"").trim() === "" ? uUrllogoDefault
			: uSystemsettingObj.url_image_nota;
		//console.log("(Bayar - contentCetaknota) vURLlogo => "+(vURLlogo))

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 			+=  vMainbody;
		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="20mm" style="height:20mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uCabanginitObj.telp_hp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				(uBahasaObj.caption_telphp||"Telp/WA")+". "+
				UFunc.capitalize(uCabanginitObj.telp_hp || "").toUpperCase()+
				//`<div>`+vIsWithlogo+`</div>`+
			`</td>
			</tr>
			`;
		//---END TELP--/

		const vFooternota = (uCabanginitObj.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(uCabanginitObj.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uCabanginitObj.nama_klinik || "DENTES").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uCabanginitObj.alamat_klinik || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_TRFOOTERNOTA_/g,vFooternota);

		return vHTMLs		
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiProsessimpan = () => {
		uFuncname = apiProsessimpan.name;

		const vElstombol	= uElBtnsimpan;
		vElstombol && (vElstombol.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			clearTimeout(vTimeout);
		},3500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datasetting: JSON.stringify(uFormfieldObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_konfigdata/pr_nota";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElstombol && (vElstombol.disabled = false);
			if(output_string.sukses) {
				const vSettingObj = uSystemsettingObj;
				vSettingObj.url_image_nota = output_string.newlogourl || uFormfieldObj.url_image_nota;
				uDispatch({type: "set", gSystemsetting: vSettingObj});

				const vCetakObj = {
					lebarkertas: uFormfieldObj.nota_lebar,
					marginkiri: uFormfieldObj.margin_kiri,
					fontsize: uFormfieldObj.font_size,
					fontstyle: uFormfieldObj.font_style,
					view_image_nota: uFormfieldObj.view_image_nota,
				}
				localStorage.setItem("settingnota",JSON.stringify(vCetakObj));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElstombol && (vElstombol.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	return()=>{ }
	},[]);
	/*React.useEffect(()=>{
		if((uSystemsettingObj.url_image_nota||"").trim() === "")
			uSystemsettingObj.url_image_nota = pjson.homepage+"api/images/header_nota.png";
	},[uSystemsettingObj,uSystemsettingObj.url_image_nota])//-*/
	React.useEffect(()=>{
		if(!uFrameExport) return;

		const pri 		= uFrameExport.contentWindow; if(!pri) return;
		const vURLlogo	= (uSystemsettingObj.url_image_nota||"").trim() === "" ? uUrllogoDefault
			: uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	/*if(isMobile) return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>Maaf, Fitur Ini Belum dapat Digunakan dengan Perangkat Mobile</CCardBody>
		</CCard>
	)//-*/
	if(uHTMLError500)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHTMLError500)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CRow className="mx-0">
		<CCol xs="12" md="6" className="px-0 px-md-1">
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<strong>{uBahasaObj["menus_"+uActiveroute.toLowerCase()]}</strong>
			<CLink className="classikontabel classikonreset classcursorpointer"
				onClick={()=>hdlKlikReset()}/>
			</CCardHeader>

			<CCardBody className="py-1">{loadContentForm()}</CCardBody>
			<CCardFooter className="d-flex justify-content-between align-items-center">
				<MyButton
					size="sm"
					onClick={()=>hdlKlikSimpan()}
					id="btnSimpan">
					{uBahasaObj.word_simpan||"word_simpan"}
				</MyButton>
				<CButton onClick={()=>hdlKlikCetak()} 
					color="primary"
					className="text-white" 
					size="sm"
					id="btnCetak">
					{uBahasaObj.caption_testprint||"caption_testprint"}
					<CIcon icon="cilArrowRight" className="ms-2"/>
				</CButton>
			</CCardFooter>
		</CCard>
		</CCol>

		<CCol xs="12" md="6" className="px-0 px-md-1 d-none d-md-block">
		<CCard className="classbgcard">
			<CCardHeader>
			<strong>{uBahasaObj.caption_tampilannota||"caption_tampilannota"}</strong>
			</CCardHeader>
			<CCardBody style={{fontFamily:uSettingcetakObj.fontstyle,fontSize:uSettingcetakObj.fontsize+"px"}}>
			{loadContentView()}
			</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)//>
}
	
export default Nota;
